<template>
  <div>
    <section v-if="sortedWarnings.length > 0" class="section">
      <div class="container">
        <div v-for="(level, index) in levels" :key="index" class="pb-5">
          <div v-if="getWarningsByLevel(level).length > 0">
            <p class="has-text-weight-bold">{{ level }}</p>

            <div
              v-for="(warning, index_warning) in getWarningsByLevel(level)"
              :key="index_warning"
            >
              <div class="pb2">
                <article
                  class="message"
                  :class="getWarningColor(warning.Level)"
                >
                  <div class="message-body">
                    <div
                      v-if="showType || showName"
                      class="has-text-weight-bold"
                    >
                      <span v-if="showType">{{ warning.Type }}&nbsp;</span>
                      <span v-if="showName">{{ warning.Name }}</span>
                    </div>
                    {{ warning.Description }}
                    <div v-if="warning.Data" class="is-italic">
                      {{ warning.Data }}
                    </div>
                    <div v-if="warning.MeetingtypeId > 0">
                      <span>Meeting type: </span>
                      <span class="is-italic">{{
                        warning.MeetingtypeId | getMeetingtypeName
                      }}</span>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    warnings: {
      type: Array,
      default: function() {
        return []
      },
    },

    level: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: '',
    },

    showType: {
      type: Boolean,
      default: true,
    },

    showName: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      levels: [],
    }
  },

  computed: {
    sortedLevels() {
      let levels = this.levels

      if (this.level) {
        levels = levels.filter((l) => l === this.level)
      }

      return levels
    },

    sortedWarnings() {
      let warnings = this.warnings

      if (this.level) {
        warnings = warnings.filter((w) => w.Level === this.level)
      }

      if (this.type) {
        warnings = warnings.filter((w) => w.Type === this.type)
      }

      return warnings
    },
  },

  created() {
    this.levels.push('Critical')
    this.levels.push('High')
    this.levels.push('Medium')
    this.levels.push('Low')
  },

  methods: {
    getWarningColor(level) {
      let background = 'is-white'

      if (level === 'Critical') {
        background = 'is-danger'
      }

      if (level === 'High') {
        background = 'is-warning'
      }

      if (level === 'Medium') {
        background = 'is-white'
      }

      if (level === 'Low') {
        background = 'is-white'
      }

      return background
    },

    getWarningsByLevel(level) {
      let warnings = this.sortedWarnings

      if (warnings.length > 0) {
        warnings = warnings.filter((w) => w.Level === level)
      }

      return warnings
    },
  },
}
</script>

<style></style>
